import { useSettings } from "../../Networking/useSettings";

export const useUnlimitedPrices = () => {
  const { unlimited, unlimitedFakePriceIncreaseInCents } = useSettings();

  const standardAmount = unlimited?.standardAmountInCents
    ? unlimited.standardAmountInCents / 100
    : 300;

  const discountedAmount = unlimited?.discountedAmountInCents
    ? unlimited.discountedAmountInCents / 100
    : 100;

  const discountAmount =
    unlimited?.standardAmountInCents && unlimited.discountedAmountInCents
      ? unlimited.standardAmountInCents / 100 -
        unlimited.discountedAmountInCents / 100
      : 200;

  const fakeAmount =
    unlimitedFakePriceIncreaseInCents && unlimited?.standardAmountInCents
      ? (unlimited.standardAmountInCents + unlimitedFakePriceIncreaseInCents) /
        100
      : undefined;

  return { standardAmount, discountedAmount, discountAmount, fakeAmount };
};

export default useUnlimitedPrices;

import { usePastOrders } from "../Networking/PastOrdersContext";
import { useMemo } from "react";
import dayjs from "dayjs";

const useRecentOrders = () => {
  const { orders, isLoading } = usePastOrders();

  const today = useMemo(() => dayjs(), []);
  const twoDaysAgo = today.subtract(2, "days").format("YYYY-MM-DD");

  const recentOrders = orders?.filter(
    (order) =>
      dayjs(order.expectedShippingDate).isAfter(twoDaysAgo) &&
      !order.cancelledAt,
  );
  return { recentOrders, isLoading };
};

export { useRecentOrders };

import React, { useEffect } from "react";
import { useSettings } from "../Networking/useSettings";
import { Link, useNavigate } from "react-router-dom";
import styles from "../Home/styles.module.css";
import { useTranslation } from "react-i18next";
import homeStyles from "../Home/styles.module.css";
import { Col, Layout, Row } from "antd";
import { Content, Header } from "antd/es/layout/layout";

const ShopClosed = () => {
  const { shopClosed } = useSettings();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!shopClosed) {
      navigate("/");
    }
  }, [shopClosed, navigate]);

  return (
    <Layout style={{ padding: 100 }}>
      <Header
        style={{
          width: "100%",
          padding: 0,
          zIndex: 1,
          height: 55,
          marginTop: -24,
          backgroundColor: "transparent",
        }}
      >
        <div className={styles.titleCentered}>{t("Shop chiuso")}</div>
      </Header>
      <Content>
        <Row justify="center">
          <Col span={8} xs={24} md={12} xl={8} style={{ padding: "0 24px" }}>
            <div className={styles.banner} style={{ textAlign: "center" }}>
              <img
                className={homeStyles.orderIcon}
                src={"/icons/dish.svg"}
                alt={"yummy dish"}
                style={{ width: 48, height: 48, marginBottom: 24 }}
              />

              <p
                className={styles.titleCentered}
                style={{ fontSize: 18, marginBottom: 32 }}
              >
                {t("Se hai domande, contatta la nostra assistenza")}
              </p>

              <img
                style={{
                  width: "100%",
                  maxWidth: 400,
                  borderRadius: 8,
                  margin: "0 auto",
                }}
                src={"/images/generic-dishes.avif"}
                alt={"delicious Humamy dishes"}
              />
            </div>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={24} style={{ textAlign: "center" }}>
            <Link
              target="_blank"
              type="primary"
              to="mailto:support-de@humamy.com"
              style={{ marginBottom: 32 }}
            >
              {t("Contatta l'assistenza")}
            </Link>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default ShopClosed;

import { Typography, Space, Button } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

const { Title, Paragraph } = Typography;

const AccountDeletion = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Space
      direction="vertical"
      size="large"
      style={{ width: "100%", padding: "20px" }}
    >
      <Title level={2}>{t("Istruzioni Cancellazione Account")}</Title>

      <Paragraph>
        {t("Per cancellare il tuo account, segui questi step:")}
      </Paragraph>

      <Space direction="vertical">
        <Paragraph>
          1. <Link to="/">{t("Accedi con il tuo account")}</Link>
        </Paragraph>

        <Paragraph>2. {t("Clicca su Assistenza")}</Paragraph>

        <Paragraph>
          3.{" "}
          {t(
            "Nella richiesta di assistenza, specifica che vuoi cancellare il tuo account",
          )}
        </Paragraph>
      </Space>

      <Button type="primary" onClick={() => navigate("/")}>
        {t("Login")}
      </Button>
    </Space>
  );
};

export default AccountDeletion;

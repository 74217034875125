import { Typography, Space } from "antd";
import { useTranslation } from "react-i18next";

const { Title, Paragraph } = Typography;

const FoodScanAISupport = () => {
  const { t } = useTranslation();

  return (
    <Space
      direction="vertical"
      size="large"
      style={{ width: "100%", padding: "20px" }}
    >
      <Title level={2}>
        {t("Istruzioni Richiesta Supporto per l'app FoodScan AI")}
      </Title>

      <Paragraph>
        {t("Per richiedere supporto per l'app FoodScanAI, scrivi una email a:")}
      </Paragraph>
      <Paragraph>
        <a href="mailto:it-support-team@humamy.com">
          it-support-team@humamy.com
        </a>
      </Paragraph>
      <Paragraph>
        {t(
          "Per favore, includi una descrizione dettagliata del problema che stai riscontrando.",
        )}
      </Paragraph>
    </Space>
  );
};

export default FoodScanAISupport;
